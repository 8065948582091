import React, { useEffect, useState } from "react";
import useSession from "../hooks/useSession";
import CircleAccCorsi from "../assets/CircleAccCorsi.png";
import CircleAccAreaPers from "../assets/CircleAccAreaPers.png";
import CircleAccWebinar from "../assets/CircleAccWebinar.png";
import CircleSYMrewards from "../assets/CircleSYMrewards.png";
import BannerHomeSTB from "../assets/BannerHomeSTB.png";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AxiosClient from "../client/client";
import Carousel from "react-bootstrap/Carousel";
import { jwtDecode } from "jwt-decode";
import { nanoid } from "nanoid";

const Home = () => {
  const session = useSession();
  const client = new AxiosClient();
  const navigate = useNavigate();
  const [lastCourses, setLastCourses] = useState([]);
  const [lastWebinars, setLastWebinars] = useState([]);

  const getLastWebinars = async () => {
    try {
      const response = await client.get("/webinars/latest");
      setLastWebinars(response);
    } catch (e) {
      console.log(e);
    }
  };

  const getLastCourses = async () => {
    try {
      const response = await client.get("/courses/latest");
      setLastCourses(response);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getLastWebinars();
    getLastCourses();
  }, []);

  //prova per vedere se funziona il controllo del token

  const logout = () => {
    // Rimuovi il token di autenticazione
    localStorage.removeItem("loggedInUser");
    navigate("/");
  };

  ///////////////////////////////////////////////////FUNZIONA
  // useEffect(() => {
  //   const fetchProtectedData = async () => {
  //     const token = localStorage.getItem("loggedInUser"); // Recupera il token dallo storage locale

  //     if (!token) {
  //       logout();
  //       return;
  //     }

  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_SERVER_BASE_URL}/apiProtected`,
  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         const data = await response.json();
  //         //console.log("TUTTO OK");
  //       } else if (response.status === 403) {
  //         logout();
  //         //console.log("403");
  //       } else {
  //         logout();
  //         //console.log("ELSE");
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   fetchProtectedData();
  // }, []);

  useEffect(() => {
    const fetchProtectedData = async () => {
      const token = localStorage.getItem("loggedInUser");

      if (!token) {
        // Se non c'è il token, fai il logout e rimanda al login
        logout();
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/apiProtected`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          if (
            data.tokenStatus === "expired" ||
            data.tokenStatus === "invalid"
          ) {
            // Token scaduto o non valido, fai il logout
            logout();
          }
          // Se è valido non fai nulla
        }
      } catch (err) {
        console.log(err);
        logout(); // In caso di errore, fai comunque logout
      }
    };

    fetchProtectedData();
  }, []);

  return (
    <>
      <Container className="margin_T150 text-center">
        <div className="text-start">
          <h2 className="mb-4">Benvenutə nella tua area riservata!</h2>
          {/* <p>
          Avrai accesso esclusivo al tuo resoconto acquisti, a sconti
          personalizzati e al cashback guadagnato. <br /> Abbiamo preparato per
          te una serie di webinar gratuiti (che verranno caricati a breve) su
          Autodesk Inventor e Vault che potrai seguire in qualsiasi momento e
          dal 2025 lanceremo una serie di mini-corsi che ti aiuteranno a
          migliorare le tue competenze. <br /> Resta connesso per non perdere le
          nostre novità!
        </p> */}
          {/* <p>
          <strong> Black Friday esplosivo con SymbioTech! </strong>{" "}
        </p>
        <p> Cosa devi fare?</p>
        <div className="d-flex justify-content-center">
          <ul className="text-start">
            <li>Entra nell'area SymbioTech Rewards.</li>
            <li>
              Attiva il programma: in pochi click otterrai subito i tuoi 50€.
            </li>
            <li>Goditi il 5% di cashback su tutti i servizi</li>
          </ul>
        </div>
        <p>
          Avrai accesso esclusivo al tuo resoconto acquisti, a sconti
          personalizzati e al cashback guadagnato. <br /> Abbiamo preparato per
          te una serie di webinar gratuiti (che verranno caricati a breve) su
          Autodesk Inventor e Vault che potrai seguire in qualsiasi momento e
          dal 2025 lanceremo una serie di mini-corsi che ti aiuteranno a
          migliorare le tue competenze. <br /> Resta connesso per non perdere le
          nostre novità!
        </p> */}

          <p> Cosa devi fare?</p>

          <ul className="">
            <li>Entra nell'area SymbioTech Rewards.</li>
            <li>
              Attiva il programma: in pochi click otterrai subito i tuoi 50€.
            </li>
            <li>Goditi il 5% di cashback su tutti i servizi</li>
          </ul>

          <p>
            Avrai accesso esclusivo al tuo resoconto acquisti, a sconti
            personalizzati e al cashback guadagnato. <br /> Abbiamo preparato
            per te una serie di webinar gratuiti (che verranno caricati a breve)
            su Autodesk Inventor e Vault che potrai seguire in qualsiasi momento
            e dal 2025 lanceremo una serie di mini-corsi che ti aiuteranno a
            migliorare le tue competenze. <br /> Resta connesso per non perdere
            le nostre novità!
          </p>
        </div>
      </Container>

      <Container fluid>
        <Row className="d-flex flex-wrap justify-content-center my-5">
          <Col
            className="boxImmagini d-flex flex-wrap justify-content-center"
            sm={12}
            xl={12}
          >
            {/* <Link to={"/corsi"}> */}
            <Link to={"/corsiList"}>
              <img
                className="m-3 mx-5 circleImgLink"
                src={CircleAccCorsi}
                alt=""
              />
            </Link>
            {/* <Link to={"/webinar"}> */}
            <Link to={"/webinar"}>
              <img
                className="m-3 mx-5 circleImgLink"
                src={CircleAccWebinar}
                alt=""
              />
            </Link>
            {/* <Link to={`/dettagliAccount/${session.id}`}>
              <img
                className="m-3 circleImgLink"
                src={CircleAccAreaPers}
                alt=""
              />
            </Link> */}
            {session.role === "azienda" && (
              <Link to={`/aziendaInfo/${session.id}`}>
                <img
                  className="m-3 mx-5 circleImgLink"
                  src={CircleSYMrewards}
                  alt=""
                />
              </Link>
            )}
          </Col>
        </Row>
      </Container>

      {/* CAROSELLI ULTIMI WEBINAR E CORSI */}
      <Container fluid>
        <Row className="d-flex flex-wrap justify-content-center mb-5">
          <Col
            // className=" d-flex flex-wrap justify-content-around"
            className=" d-flex flex-wrap justify-content-center"
            sm={12}
            xl={12}
          >
            <div>
              <h5 className="text-center text_Color_Grigio">
                NUOVI WEBINAR DISPONIBILI
              </h5>
              <Carousel
                pause={"hover"}
                style={{ width: "400px" }}
                variant={"dark"}
                indicators={false}
                className="mt-3 mx-5"
              >
                {lastWebinars &&
                  lastWebinars.webinars?.map((webinar) => {
                    return (
                      <Carousel.Item key={nanoid()} interval={5000}>
                        <Link
                          to={`/webinar/${webinar._id}`}
                          className="clear_link_black text-center altezzaTitoloCarosello"
                        >
                          <img
                            src={webinar.cover}
                            alt=""
                            style={{
                              height: "200px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <Carousel.Caption></Carousel.Caption>{" "}
                          <h3 className="mt-2 altezzaTitoloCarosello">
                            {webinar.titolo}
                          </h3>
                        </Link>
                      </Carousel.Item>
                    );
                  })}
              </Carousel>
            </div>
            <div>
              <h5 className="text-center text_Color_Grigio">
                NUOVI CORSI DISPONIBILI
              </h5>
              <Carousel
                pause={"hover"}
                style={{ width: "400px" }}
                variant={"dark"}
                indicators={false}
                className="mt-3 mx-5"
              >
                {lastCourses &&
                  lastCourses.courses?.map((course) => {
                    return (
                      <Carousel.Item key={nanoid()} interval={5000}>
                        <Link
                          to={`/dettagliCorso/${course._id}`}
                          className="clear_link_black text-center altezzaTitoloCarosello"
                        >
                          <img
                            src={course.cover}
                            alt=""
                            style={{
                              height: "200px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <Carousel.Caption></Carousel.Caption>

                          <h3 className="mt-2 altezzaTitoloCarosello">
                            {course.titolo}
                          </h3>
                        </Link>
                      </Carousel.Item>
                    );
                  })}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>

      {/* BANNER STBOOSTERS */}
      <div className="d-flex justify-content-center mt-3 mb-5 d-none d-md-flex d-xxl-flex">
        <Link
          target="_blank"
          to="https://www.symbiotech.it/st-boosters/"
          className="text-center w-50 "
        >
          <img className="w-100 bannerPubbl" src={BannerHomeSTB} alt="" />
        </Link>
      </div>
      <div className="d-flex justify-content-center mt-3 mb-5 d-md-none">
        <Link
          target="_blank"
          to="https://www.symbiotech.it/st-boosters/"
          className="text-center"
        >
          <img className="w-100" src={BannerHomeSTB} alt="" />
        </Link>
      </div>
      {/* SCHERMO GRANDE */}
      {/* SCHERMO PICCOLO */}
    </>
  );
};

export default Home;
